<template>
  <div>
    <!-- Filter Section -->
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
          <!-- year  -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('globalSingular.year')"
              label-for="year"
            >
              <v-select
                  id="year"
                  class="select-size-sm"
                  ref="firstFocusSelectInput"
                  v-model="form.year"
                  :options="LOV.year"
                />
            </b-form-group>
          </b-col>
          <!-- month -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('globalSingular.month')"
              label-for="month"
            >
               <v-select
                  id="month"
                  class="select-size-sm"
                  ref="firstFocusSelectInput"
                  v-model="form.month"
                  :reduce="field => field.value"
                  :options="LOV.monthLov"
                />
            </b-form-group>
          </b-col>
          <!-- button search/filter  -->
          <b-col cols="12" md="3">
            <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
              {{ $t('globalActions.find') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <div class="mt-1 mb-1 d-flex justify-content-end">
      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">
          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>
          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>
        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">
      <b-card-body class="text-center">
        <b-card-title>{{ companyName }}</b-card-title>
        <b-card-sub-title>
          <div>{{ $t('apps.monitoringAgent.moduleName') }}</div>
          <div class="mt-sm-1">{{ periodReportText }}</div>
        </b-card-sub-title>
      </b-card-body>

        <b-table
            id="refListTable"
            ref="refListTable"
            no-provider-paging
            no-provider-filtering
            :filter-included-fields="columnToBeFilter"
            :fields="tableColumns"
            :items="itemLists"
            :filter="searchQuery"
            responsive
            primary-key="id"
            show-empty
            :busy="isBusy"
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
          >
            <template #table-busy>
              <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
                <div class="mb-1 text">
                  <b-spinner class="align-middle"/>
                </div>
                <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
              </div>
            </template>

            <!-- Column: Contact -->
            <template #cell(contact)="{ item }">
              <b-link :to="{ name: 'apps-monitoring-agent', query: { agent: item.contact_id, year: yearChoose }}">
                <span :class="['font-weight-bolder','text-right',(item.contact_id === true) && 'font-italic font-weight-bolder']">
                  {{ item.contact }}
                </span>
              </b-link>
            </template>

            <!-- Column: Berat -->
            <template #cell(berat)="{ item }">
              <span :class="['text-right', 'text-nowrap']">
                {{ formatCurrency(item.berat, false) }}
              </span>
            </template>


            <!-- Column: Royalti Brand -->
            <template #cell(royalti_brand)="{ item }">
              <span :class="['text-right', 'text-nowrap', (item.royalti_brand > 0) ? 'text-success' : 'text-danger']">
                <div v-if="item.royalti_brand >= 0 ">
                  {{ formatCurrency(item.royalti_brand,false) }}
                </div>
                <div v-else>
                 ( {{ formatCurrency(item.royalti_brand * -1, false) }} )
                </div>
              </span>
            </template>

            <!-- Column: Total Resi -->
            <template #cell(total_resi)="{ item }">
              <span :class="['text-right', 'text-nowrap']">
                {{ formatCurrency(item.total_resi,false) }}
              </span>
            </template>


            <!-- Column: Royalti ICT -->
            <template #cell(royalti_ict)="{ item }">
              <span :class="['text-right', 'text-nowrap', (item.royalti_ict > 0) ? 'text-success' : 'text-danger']">
                <div v-if="item.royalti_ict >= 0 ">
                  {{ formatCurrency(item.royalti_ict,false) }}
                </div>
                <div v-else>
                 ( {{ formatCurrency(item.royalti_ict * -1, false) }} )
                </div>
              </span>
            </template>

            <!-- Column: Grand Total -->
            <template #cell(grand_total)="{ item }">
              <span :class="['text-right', 'text-nowrap', (item.royalti_brand + item.royalti_ict > 0) ? 'text-success' : 'text-danger']">
                <div v-if="item.royalti_brand + item.royalti_ict >= 0 ">
                  {{ formatCurrency(item.royalti_brand + item.royalti_ict,false) }}
                </div>
                <div v-else>
                 ( {{ formatCurrency((item.royalti_brand + item.royalti_ict) * -1, false) }} )
                </div>
              </span>
            </template>

        </b-table>


    </b-card>

  </div>
</template>
<script>
import vSelect from 'vue-select'
import { ref, onMounted } from '@vue/composition-api'
// import useHttp from '@/comp-functions/useHttp'
import useListTable from '@/comp-functions/useListTable'
import { formatCurrency, unformatNumber } from '@/utils/formatter'
import { getYears } from '@/utils'
import moment from 'moment'


import {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BSpinner,
    BLink
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BSpinner,
    BLink,
    vSelect
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const periodReportText = ref()


    // isian yang bakalan ditampung pas milih
    const form = ref({
        agent: '',
        year: moment().year(),
        month: null,
    })

    const yearChoose = form.value.year
    const dateNow    = new Date();
    const getMonth   = dateNow.getMonth() + 1
    form.value.month = getMonth

    // filter
    const columnToBeFilter = []
    const tableColumns = [
      { key: 'contact', label: 'Agent' },
      { key: 'berat', label: 'Berat', thClass: 'text-right text-nowrap', tdClass: 'text-right' },
      { key: 'royalti_brand', label: 'Fee Brand', thClass: 'text-right text-nowrap', tdClass: 'text-right' },
      { key: 'total_resi', label: 'Total Resi', thClass: 'text-right text-nowrap', tdClass: 'text-right' },
      { key: 'royalti_ict', label: 'Fee ICT', thClass: 'text-right text-nowrap', tdClass: 'text-right' },
      { key: 'grand_total', label: 'Grand Total', thClass: 'text-right text-nowrap', tdClass: 'text-right' }
    ]

    //data-data pilihan
    const LOV = ref({
       year: getYears(),
      monthLov: [
        {
          label: 'January',
          value: 1
        },
        {
          label: 'February',
          value: 2
        },
        {
          label: 'March',
          value: 3
        },
        {
          label: 'April',
          value: 4
        },
        {
          label: 'May',
          value: 5
        },
        {
          label: 'June',
          value: 6
        },
        {
          label: 'July',
          value: 7
        },
        {
          label: 'August',
          value: 8
        },
        {
          label: 'September',
          value: 9
        },
        {
          label: 'October',
          value: 10
        },
        {
          label: 'November',
          value: 11
        },
        {
          label: 'December',
          value: 12
        }
    ],
    })


    onMounted(async () => {
      const params = [
        `year=${form.value.year}`,
        `month=${form.value.month}`,
      ]
      const url = `reports/monitoring/agentYear?${ params.join('&') }`
      await fetchLists(url)

    })

    return {
        form,
        LOV,
        columnToBeFilter,
        tableColumns,
        searchQuery,
        periodReportText,
        isBusy,
        itemLists,
        perPage,
        currentPage,
        totalList,
        dataMeta,
        perPageOptions,
        isSortDirDesc,
        refListTable,
        statusFilter,
        yearChoose,
        fetchLists,
        formatCurrency
    }
  },
  methods: {
    getReport () {
    const params = [
           `year=${this.form.year}`,
           `month=${this.form.month}`,
         ]

      const url = `reports/monitoring/agentYear?${ params.join('&') }`
      this.fetchLists(url)
    },
    print () {
      window.print()
    },
   exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists;

        const headerTitle = this.tableColumns.map(column => column.label);
        const headerVal = this.tableColumns.map(column => column.key);

        const data = this.formatJson(headerVal, list).map(row => {
          return row.map(cell => {
            return unformatNumber(cell);
          });
        });

        // Avoid null value, change it to empty string
        const validData = data.map(row => row.map(cell => cell != null ? cell : ''));

        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            [this.$t('apps.reportCashBank.singular.cashBankReport'), '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data: validData,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 5, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 5, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } }
          ],
          filename: `${this.$t('apps.reportCashBank.singular.cashBankReport')} ${this.periodReportText}`,
          autoWidth: false,
          bookType: 'xlsx'
        });
      });
    },

    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  },
}

</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
